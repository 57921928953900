const DEFAULT_PREFERENCES = {
  path: '/helpcenter',
  updateUrlOnMenuClick: false,
  content: {
    subItemsToCheckNoHplusText: [
      /*

      Provide an array of objects with the analyticsId parameter of the subItem you want to check for a NoHplusText

      {
        "analyticsId": "Learn More"
      }

      */
    ],
    htmlTagReplacementList: [
      {
        stringRegex: '<h3><strong>',
        stringReplacement: '<h3>',
      },
      {
        stringRegex: '</strong></h3>',
        stringReplacement: '</h3>',
      },
    ],
    initializeWithMenuExpandedBasedOnPageId: false,
  },
  dataSource: {
    contentStack: {
      /*

      ==== The default values for calling SMB APIs on each STACK are already defined in code,
        so only partners need to send the values to call their APIs.

      apiKey: 'bltcad87795ce18c6ce',
      apiConfig: {
        accessToken: 'cs8e7a3b88dc78dbe7eb302cbe',
        environment: 'dev',
        url: 'https://www.hpsmartdev.com/content/v3/content_types/smart_help_sections/entries',
      },

      */
      addSlugFromUrls: false,
      environmentSectionTitles: {
        fetch: false,
        apiKey: '',
        accessToken: '',
        environment: '',
        url: '',
      },
    },
  },
  customMenuFilters: {

    /*

    ==== The menu item that has the property "slug" with value SLUG_HERE will only appear if the account has FEATURE_HERE.

    FEATURE_HERE: {
      slug: 'SLUG_HERE',
    },

    ==== Menu filters already added in code

    hpPlus: {
      slug: 'SLUG_HERE',
    },
    instantInk_printPlans: {
      slug: 'SLUG_HERE',
    },
    smartAdvance: {
      slug: 'SLUG_HERE',
    },

    */
  },
};

export default DEFAULT_PREFERENCES;
