import { mixins } from '@jarvis/react-portal-addons';
import styled from 'styled-components';
import primitives from '@veneer/primitives';

export const Container = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: 2;

  h3 {
    margin-bottom: ${primitives.layout.size4}px;
    font-size: ${primitives.typography.size5};
    line-height: ${primitives.typography.lineHeight5};
  }

  h4 {
    margin-bottom: ${primitives.layout.size3}px;
  }

  p {
    margin-bottom: ${primitives.layout.size4}px;
  }

  a {
    color: ${({ theme }) => theme.color.primary.default};
  }

  ul {
    margin: ${primitives.layout.size4}px 0 ${primitives.layout.size4}px
      ${primitives.layout.size5}px;
    list-style-position: inside;
  }

  ul li {
    position: relative;
    list-style: none;
    padding-left: ${primitives.layout.size3}px;
  }

  ul li::before {
    content: '•';
    position: absolute;
    left: 0;
  }

  ol {
    margin: 0 0 0 ${primitives.layout.size5}px;
    list-style-type: decimal;
    list-style-position: inside;
  }

  ol ul {
    margin: 0 0 0 ${primitives.layout.size5}px;
  }

  ${mixins.lg(`
    grid-column-start: 2;
    grid-column-end: 12;
    grid-row-start: 1;
  `)}
`;

export const Title = styled.h1`
  && {
    margin-bottom: ${primitives.layout.size4}px;
  }
`;

export const DeviceSection = styled.section`
  margin-bottom: ${primitives.layout.size5}px;

  ol {
    margin: 0 0 ${primitives.layout.size3}px 0;
  }
`;

export const DeviceSubtitle = styled.h2`
  margin-bottom: ${primitives.layout.size4}px;
`;

export default Container;
