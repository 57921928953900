import styled from 'styled-components';

export const Content = styled.div`
  height: 100%;

  > div {
    height: 100%;
  }
`;

export default Content;
