import React from 'react';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import { Route, Router, Switch } from 'react-router-dom';
import { usePreferencesContext } from '@jarvis/react-portal-addons';
import { path } from 'ramda';
import HelpCenter from './pages/HelpCenter';
import { Content } from './styles';

export const history = createBrowserHistory();

const App = props => {
  const navigation = path(['shell', 'v1', 'navigation'], props);
  const preferences = usePreferencesContext();

  return (
    <Content>
      <Router history={navigation || history}>
        <Switch>
          <Route path={preferences.path}>
            <HelpCenter {...props} />
          </Route>
        </Switch>
      </Router>
    </Content>
  );
};

App.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any),
  stack: PropTypes.number,
};

App.defaultProps = {
  properties: {},
  stack: 0,
};

export default App;
