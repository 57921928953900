const localeMap = {
  'de-AT': 'de-DE',
  'de-CH': 'de-DE',
  'de-LI': 'de-DE',
  'de-LU': 'de-DE',
  'el-CY': 'el-GR',
  'en-CA': 'en-GB',
  'en-AD': 'en-US',
  'en-AE': 'en-US',
  'en-AF': 'en-US',
  'en-AG': 'en-US',
  'en-AI': 'en-US',
  'en-AL': 'en-US',
  'en-AM': 'en-US',
  'en-AW': 'en-US',
  'en-AZ': 'en-US',
  'en-BA': 'en-US',
  'en-BB': 'en-US',
  'en-BD': 'en-US',
  'en-BH': 'en-US',
  'en-BN': 'en-US',
  'en-BS': 'en-US',
  'en-BT': 'en-US',
  'en-BW': 'en-US',
  'en-BZ': 'en-US',
  'en-CM': 'en-US',
  'en-CR': 'en-US',
  'en-CY': 'en-US',
  'en-DZ': 'en-US',
  'en-EG': 'en-US',
  'en-ET': 'en-US',
  'en-FJ': 'en-US',
  'en-GD': 'en-US',
  'en-GE': 'en-US',
  'en-GH': 'en-US',
  'en-HK': 'en-US',
  'en-ID': 'en-US',
  'en-IE': 'en-US',
  'en-IL': 'en-US',
  'en-IN': 'en-US',
  'en-IS': 'en-US',
  'en-JM': 'en-US',
  'en-JO': 'en-US',
  'en-JP': 'en-US',
  'en-KE': 'en-US',
  'en-KH': 'en-US',
  'en-KN': 'en-US',
  'en-KW': 'en-US',
  'en-KY': 'en-US',
  'en-LA': 'en-US',
  'en-LB': 'en-US',
  'en-LC': 'en-US',
  'en-LK': 'en-US',
  'en-LY': 'en-US',
  'en-MA': 'en-US',
  'en-ME': 'en-US',
  'en-MK': 'en-US',
  'en-MM': 'en-US',
  'en-MS': 'en-US',
  'en-MT': 'en-US',
  'en-MU': 'en-US',
  'en-MV': 'en-US',
  'en-MY': 'en-US',
  'en-NA': 'en-US',
  'en-NG': 'en-US',
  'en-NP': 'en-US',
  'en-NZ': 'en-US',
  'en-OM': 'en-US',
  'en-PG': 'en-US',
  'en-PH': 'en-US',
  'en-PK': 'en-US',
  'en-PR': 'en-US',
  'en-QA': 'en-US',
  'en-RS': 'en-US',
  'en-RW': 'en-US',
  'en-SA': 'en-US',
  'en-SG': 'en-US',
  'en-SX': 'en-US',
  'en-TC': 'en-US',
  'en-TH': 'en-US',
  'en-TJ': 'en-US',
  'en-TL': 'en-US',
  'en-TM': 'en-US',
  'en-TN': 'en-US',
  'en-TT': 'en-US',
  'en-TW': 'en-US',
  'en-TZ': 'en-US',
  'en-UG': 'en-US',
  'en-VC': 'en-US',
  'en-VG': 'en-US',
  'en-VI': 'en-US',
  'en-VN': 'en-US',
  'en-WS': 'en-US',
  'en-ZA': 'en-US',
  'en-ZM': 'en-US',
  'es-AR': 'es-ES',
  'es-BO': 'es-ES',
  'es-CL': 'es-ES',
  'es-CO': 'es-ES',
  'es-CU': 'es-ES',
  'es-DO': 'es-ES',
  'es-EC': 'es-ES',
  'es-GT': 'es-ES',
  'es-HN': 'es-ES',
  'es-MX': 'es-ES',
  'es-NI': 'es-ES',
  'es-PA': 'es-ES',
  'es-PE': 'es-ES',
  'es-PY': 'es-ES',
  'es-SV': 'es-ES',
  'es-UY': 'es-ES',
  'es-VE': 'es-ES',
  'fr-BE': 'fr-FR',
  'fr-BJ': 'fr-FR',
  'fr-BL': 'fr-FR',
  'fr-CA': 'fr-FR',
  'fr-CH': 'fr-FR',
  'fr-CI': 'fr-FR',
  'fr-GF': 'fr-FR',
  'fr-GP': 'fr-FR',
  'fr-HT': 'fr-FR',
  'fr-LC': 'fr-FR',
  'fr-LU': 'fr-FR',
  'fr-MC': 'fr-FR',
  'fr-MF': 'fr-FR',
  'fr-MG': 'fr-FR',
  'fr-MQ': 'fr-FR',
  'fr-NC': 'fr-FR',
  'fr-PF': 'fr-FR',
  'fr-RE': 'fr-FR',
  'fr-SN': 'fr-FR',
  'fr-YT': 'fr-FR',
  'it-CH': 'it-IT',
  'it-SM': 'it-IT',
  'it-VA': 'it-IT',
  'nl-BQ': 'nl-BE',
  'nl-CW': 'nl-BE',
  'nl-NL': 'nl-BE',
  'pt-AO': 'pt-PT',
  'pt-MO': 'pt-PT',
  'pt-MZ': 'pt-PT',
  'ro-MD': 'ro-RO',
  'sv-FI': 'sv-SE',
  'zh-MO': 'zh-TW',
  'zh-SG': 'zh-CN',
};

export default localeMap;
