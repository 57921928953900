import { Stack as WebStratusStack } from '@jarvis/web-stratus-client';
import localeMap from '../utils/localeMap';
import contentStackMock from '../mocks/content-stack.json';

const CONTENT_STACK_DEFAULT_VALUES = {
  apiKey: 'bltcad87795ce18c6ce',
  apiConfig: {
    dev: {
      accessToken: 'cs8e7a3b88dc78dbe7eb302cbe',
      environment: 'dev',
      url: 'https://www.hpsmartdev.com/content/v3/content_types/smb_smart_help_section/entries',
    },
    pie: {
      accessToken: 'cs8fd1a66d22569cf483d771f3',
      environment: 'pie',
      url: 'https://www.hpsmartpie.com/content/v3/content_types/smb_smart_help_section/entries',
    },
    stage: {
      accessToken: 'csa99792b06c7a723246302136',
      environment: 'stage',
      url: 'https://www.hpsmartstage.com/content/v3/content_types/smb_smart_help_section/entries',
    },
    prod: {
      accessToken: 'csa674c4b13d19219db6fa4e0b',
      environment: 'production',
      url: 'https://www.hpsmart.com/content/v3/content_types/smb_smart_help_section/entries',
    },
  },
};

export const toCamelCase = obj => {
  if (Array.isArray(obj)) {
    return obj.map(el => toCamelCase(el));
  }

  if (obj !== Object(obj)) {
    return obj;
  }

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key.replace(/(?!^)(_[a-zA-Z])/gi, match => match.toUpperCase().replace('_', '')),
      toCamelCase(value),
    ]),
  );
};

export const addSlugFromUrls = obj => ({
  entries: obj.entries?.map(item => {
    if (!item.slug && item.url && typeof item.url === 'string') {
      return { ...item, slug: item.url.substring(1) };
    }

    return { ...item };
  }),
});

const getHelpCenterData = ({
  locale, stack, preferences, useMock = false,
}) => {
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    if (useMock) {
      return new Promise(resolve => resolve(toCamelCase(contentStackMock)));
    }
  }

  const helpCenterLocale = localeMap[locale] || locale;

  const contentStackValues = {
    apiKey: CONTENT_STACK_DEFAULT_VALUES.apiKey,
    apiConfig: CONTENT_STACK_DEFAULT_VALUES.apiConfig[[WebStratusStack[stack]]],
    ...preferences?.dataSource?.contentStack,
  };

  const contentStackConfigApiConfig = contentStackValues.apiConfig || {};

  const endpoint = `${contentStackConfigApiConfig.url}?locale=${helpCenterLocale?.toLowerCase()}&environment=${contentStackConfigApiConfig.environment}`;

  return fetch(endpoint, {
    headers: {
      api_key: contentStackValues.apiKey,
      access_token: contentStackConfigApiConfig.accessToken,
      'Content-Type': 'application/json',
    },
  }).then(resp => resp.json())
    .then(data => (contentStackValues.addSlugFromUrls ? addSlugFromUrls(data) : data))
    .then(data => toCamelCase(data));
};

export const getEnvironmentSectionTitles = ({ locale, preferences }) => {
  const helpCenterLocale = localeMap[locale] || locale;

  const contentStackValues = preferences?.dataSource?.contentStack?.environmentSectionTitles || {};

  const endpoint = `${contentStackValues.url}?locale=${helpCenterLocale?.toLowerCase()}&environment=${contentStackValues.environment}`;

  const apiKey = contentStackValues.apiKey || preferences?.dataSource?.contentStack?.apiKey || '';

  return fetch(endpoint, {
    headers: {
      api_key: apiKey,
      access_token: contentStackValues.accessToken,
      'Content-Type': 'application/json',
    },
  }).then(resp => resp.json())
    .then(data => toCamelCase(data));
};

export default getHelpCenterData;
