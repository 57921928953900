import React, { useCallback } from 'react';
import parse, { domToReact } from 'html-react-parser';
import PropTypes from 'prop-types';
import { HyperlinkText, usePreferencesContext } from '@jarvis/react-portal-addons';
import {
  Container,
  DeviceSection,
  DeviceSubtitle,
  Title,
} from './styles';

const replacer = ({
  attribs,
  children,
  name,
  onClick,
}) => {
  if (name === 'a') {
    return (
      <HyperlinkText
        {...attribs}
        url={attribs.href}
        onClick={onClick}
      >
        {domToReact(children)}
      </HyperlinkText>
    );
  }
  return null;
};

const SideMenuContent = props => {
  const {
    title, text, textsForSpecificEnvironments,
  } = props;
  const preferences = usePreferencesContext();

  const replaceHTMLTag = useCallback(pureText => {
    const htmlTagReplacementList = preferences?.content?.htmlTagReplacementList;

    if (pureText && htmlTagReplacementList && htmlTagReplacementList.length) {
      return htmlTagReplacementList.reduce(
        (replacedText, currentReplacementRule) => replacedText.replaceAll(
          currentReplacementRule.stringRegex,
          currentReplacementRule.stringReplacement,
        ),
        pureText,
      );
    }

    return '';
  }, [preferences?.content?.htmlTagReplacementList]);

  const parseText = unparsedText => parse(unparsedText, {
    replace: domNode => replacer({
      ...domNode,
    }),
  });

  const generateText = pureText => {
    const replacedText = replaceHTMLTag(pureText);
    return parseText(replacedText);
  };

  return (
    <Container
      className="body"
      id="sideMenuContent"
    >
      <Title className="title-small" data-testid="sidenavcontent-title">
        { title }
      </Title>

      {
        text
          ? generateText(text)
          : textsForSpecificEnvironments.map(deviceTexts => (
            <DeviceSection key={deviceTexts.text}>
              <DeviceSubtitle
                className="subtitle-large"
                data-testid="sidenavcontent-device-subtitle"
              >
                {deviceTexts.title}
              </DeviceSubtitle>
              {generateText(deviceTexts.text)}
            </DeviceSection>
          ))
      }
    </Container>
  );
};

SideMenuContent.defaultProps = {
  text: '',
  title: '',
  textsForSpecificEnvironments: [],
};

SideMenuContent.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  textsForSpecificEnvironments: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  })),
};

export default SideMenuContent;
