import { useCallback, useEffect, useState } from 'react';
import { path } from 'ramda';
import {
  AccountMgtSvcClientV3,
  UserMgtSvcClientv3,
} from '@jarvis/web-stratus-client';
import {
  GRANT_CHECK_TYPE,
  GRANT_STATES,
  getOfferingOrEntitlement,
  useApiCall,
  useGrants,
  useRootContext,
} from '@jarvis/react-portal-addons';

const ENTITLEMENTS = {
  INSTANT_INK: 'ws-hp.com/instantink',
  PRINT_PLANS: 'ws-hp.com/printplans',
  PRINT_ANYWHERE: 'ws-hp.com/potg',
  SUSTAINABILITY: 'ws-hp.com/positiveprinting',
  SMART_ADVANCE: 'ws-hp.com/smart-advance',
  SMART_PRO: 'ws-hp.com/smart-pro',
  SMART_SECURITY: 'ws-hp.com/smartsecurity',
  SMART_SECURITY_BASIC: 'ws-hp.com/smartsecurity-basic',
};

const STATES_ENTITLEMENTS = {
  published: 'Published',
};

const grants = [
  {
    grant: 'ws-hp.com/smart-advance',
    states: [GRANT_STATES.ENABLED, GRANT_STATES.PENDING, GRANT_STATES.DISABLED],
  },
  {
    grant: 'ws-hp.com/printplans',
    states: [GRANT_STATES.ENABLED, GRANT_STATES.PENDING, GRANT_STATES.DISABLED],
  },
  {
    grant: 'ws-hp.com/instantink',
    states: [GRANT_STATES.ENABLED, GRANT_STATES.PENDING, GRANT_STATES.DISABLED],
  },
  { grant: 'ws-hp.com/smartsecurity', states: [GRANT_STATES.ENABLED] },
  { grant: 'ws-hp.com/smartsecurity-basic', states: [GRANT_STATES.ENABLED] },
  { grant: 'ws-hp.com/positiveprinting', states: [GRANT_STATES.ENABLED] },
  { grant: 'ws-hp.com/smart-pro', states: [GRANT_STATES.ENABLED] },
  { grant: 'ws-hp.com/potg', states: [GRANT_STATES.ENABLED] },
];

export default function useGrantsCall({
  accountId,
  authProvider,
  stack,
  init,
}) {
  const [hasInitialFetched, setHasInitialFetched] = useState(false);
  const [hasInstantInk, setHasInstantInk] = useState(false);
  const [hasPrintPlans, setHasPrintPlans] = useState(false);
  const [hasPrintAnywhere, setHasPrintAnywhere] = useState(false);
  const [hasSmartAdvance, setHasSmartAdvance] = useState(false);
  const [hasSmartPro, setHasSmartPro] = useState(false);
  const [hasSmartSecurity, setHasSmartSecurity] = useState(false);
  const [hasSmartSecurityBasic, setHasSmartSecurityBasic] = useState(false);
  const [hasSustainability, setHasSustainability] = useState(false);
  const [isHpPlus, setIsHpPlus] = useState(false);
  const [isUcde, setIsUcde] = useState(false);

  const {
    shell: {
      v1: { grants: grantsInterface, grantsHistory: grantsHistoryInterface },
    },
  } = useRootContext();

  const fetchUserInfo = useCallback(async () => {
    const userMgtSvcClient = new UserMgtSvcClientv3(stack, authProvider);
    const response = await userMgtSvcClient.getCurrentActiveUser();
    return response.data;
  }, [authProvider, stack]);

  const fetch = useCallback(async () => {
    const userInfo = await fetchUserInfo();
    const userId = userInfo.resourceId;

    const accountMgtSvcClient = new AccountMgtSvcClientV3(stack, authProvider);
    const programInfos = await accountMgtSvcClient.getProgramInfos(
      accountId,
      userId,
    );

    return programInfos;
  }, [accountId, authProvider, fetchUserInfo, stack]);

  const programInfosCall = useApiCall({
    authProvider,
    fetch,
    initialData: null,
    init,
  });

  const { grantsCall } = useGrants({
    init,
    grantsInterface,
    grantsHistoryInterface,
    checkType: GRANT_CHECK_TYPE.INDIVIDUAL,
    grants,
  });

  const isFetching = programInfosCall.isFetching || grantsCall.pending;
  const error = programInfosCall.error || grantsCall.error;

  useEffect(() => {
    if (!hasInitialFetched && programInfosCall.data && grantsCall.success) {
      const fetchInfos = async () => {
        const hpPlus = path(['planInfo', 'programLevel'], programInfosCall.data.data)
          === 'HP+';
        const ucde = path(['planInfo', 'programLevel'], programInfosCall.data.data)
          === 'UCDE';

        const instantInkOffering = getOfferingOrEntitlement(
          programInfosCall.data.data,
          ENTITLEMENTS.INSTANT_INK,
          {
            states: [STATES_ENTITLEMENTS.published],
          },
        );

        const printPlansOffering = getOfferingOrEntitlement(
          programInfosCall.data.data,
          ENTITLEMENTS.PRINT_PLANS,
          {
            states: [STATES_ENTITLEMENTS.published],
          },
        );

        const printAnywhereOffering = getOfferingOrEntitlement(
          programInfosCall.data.data,
          ENTITLEMENTS.PRINT_ANYWHERE,
          {
            states: [STATES_ENTITLEMENTS.published],
          },
        );

        const smartAdvanceOffering = getOfferingOrEntitlement(
          programInfosCall.data.data,
          ENTITLEMENTS.SMART_ADVANCE,
          {
            states: [STATES_ENTITLEMENTS.published],
          },
        );

        const smartSecurityOffering = getOfferingOrEntitlement(
          programInfosCall.data.data,
          ENTITLEMENTS.SMART_SECURITY,
          {
            states: [STATES_ENTITLEMENTS.published],
          },
        );

        const smartSecurityBasicOffering = getOfferingOrEntitlement(
          programInfosCall.data.data,
          ENTITLEMENTS.SMART_SECURITY_BASIC,
          {
            states: [STATES_ENTITLEMENTS.published],
          },
        );

        const {
          [ENTITLEMENTS.SMART_SECURITY]: smartSecurityGrant,
          [ENTITLEMENTS.INSTANT_INK]: instantInkGrant,
          [ENTITLEMENTS.PRINT_PLANS]: printPlansGrant,
          [ENTITLEMENTS.SMART_PRO]: smartProGrant,
          [ENTITLEMENTS.SUSTAINABILITY]: sustainabilityGrant,
          [ENTITLEMENTS.PRINT_ANYWHERE]: printAnywhereGrant,
          [ENTITLEMENTS.SMART_SECURITY_BASIC]: smartSecurityBasicGrant,
          [ENTITLEMENTS.SMART_ADVANCE]: smartAdvanceGrant,
        } = grantsCall.data;

        setIsHpPlus(hpPlus);
        setIsUcde(ucde);
        setHasInstantInk(instantInkOffering || instantInkGrant);
        setHasPrintPlans(printPlansOffering || printPlansGrant);
        setHasPrintAnywhere(printAnywhereOffering || printAnywhereGrant);
        setHasSmartPro(smartProGrant);
        setHasSustainability(sustainabilityGrant);
        setHasSmartAdvance(smartAdvanceOffering || smartAdvanceGrant);
        setHasSmartSecurity(smartSecurityOffering || smartSecurityGrant);
        setHasSmartSecurityBasic(
          smartSecurityBasicOffering || smartSecurityBasicGrant,
        );
      };
      fetchInfos();
      setHasInitialFetched(true);
    }
  }, [
    grantsCall.data,
    grantsCall.success,
    hasInitialFetched,
    programInfosCall.data,
  ]);

  return {
    error,
    hasPrintPlans,
    hasInstantInk,
    hasPrintAnywhere,
    hasSmartAdvance,
    hasSmartPro,
    hasSmartSecurity,
    hasSmartSecurityBasic,
    hasSustainability,
    isFetching,
    isHpPlus,
    isUcde,
  };
}
