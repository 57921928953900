import { mixins } from '@jarvis/react-portal-addons';
import primitives from '@veneer/primitives';
import styled from 'styled-components';
import { SideMenu as VeneerSideMenu } from '@veneer/core';

export const ErrorAndLoaderContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: ${primitives.layout.cornerRadius4}px;
`;

export const SideMenu = styled(VeneerSideMenu)`
  &&& {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: ${primitives.layout.cornerRadius4}px;

    nav {
      width: 100%;
    }

    div > span {
      margin-left: 0;
    }

    div > button {
      display: none;
    }

    .subMenuItem {
      padding: 0;

      > a {
        padding: 4px 0 4px 18px;
      }
    }

    .subMenuItem a[data-selected]::before {
      height: 100%;
    }
  }
`;

export const SideMenuContainer = styled.div`
  border-radius: ${primitives.layout.cornerRadius4}px;
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: 1;
  width: 100%;
  min-height: 236px;

  ${mixins.lg(`
    grid-column-start: 1;
    grid-column-end : 2;
    width: 300px;
  `)}

  ${mixins.xl(`
    width: 512px;
  `)}
`;
