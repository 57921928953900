import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { ThemeProvider } from 'styled-components';
import { NavigationArea, useI18n } from '@jarvis/react-portal-addons';
import { useTheme } from '@veneer/theme';
import { IconChevronUp } from '@veneer/core';
import SideMenuNav from '../SideMenuNav';
import SideMenuContent from '../SideMenuContent';
import {
  Button,
  ButtonContainer,
  Container,
  HelpCenterContainer,
  NavigationContainer,
} from './styles';
import { helpCenterScreenDisplayed, publishEvent } from '../../utils/analytics';

const HelpCenter = props => {
  const navigation = path(['shell', 'v1', 'navigation'], props);

  const theme = useTheme();

  const { t } = useI18n();

  useEffect(() => {
    publishEvent(helpCenterScreenDisplayed(props.properties.analyticsScreenPath));
  }, [props.properties.analyticsScreenPath]);

  const navigateToHome = useCallback(() => {
    if (navigation) {
      navigation.push('/');
    }
  }, [navigation]);

  const breadcrumbsItems = useMemo(() => (
    [
      {
        text: t('breadcrumb.home'),
        onClick: e => {
          e.preventDefault();
          navigateToHome();
        },
        url: '/',
      },
      {
        text: t('breadcrumb.helpCenter'),
        onClick: e => {
          e.preventDefault();
        },
      },
    ]
  ), [navigateToHome, t]);

  const scrollToTheTop = () => {
    document.querySelector('.mainContainer').scrollIntoView({
      behavior: 'smooth',
    });
  };

  const [sideMenuData, setSideMenuData] = useState({
    text: t('sideMenuContent.text'),
    title: t('sideMenuContent.title'),
  });

  return (
    <ThemeProvider theme={theme}>
      <Container
        className="mainContainer"
        data-testid="helpCenter"
      >
        <NavigationContainer>
          <NavigationArea
            breadcrumbsItems={breadcrumbsItems}
          />
        </NavigationContainer>

        <HelpCenterContainer>
          <SideMenuNav
            setSideMenuData={value => setSideMenuData(value)}
            {...props}
          />
          <SideMenuContent
            {...sideMenuData}
          />
          <ButtonContainer>
            <Button
              leadingIcon={<IconChevronUp />}
              appearance="ghost"
              onClick={() => scrollToTheTop()}
            >
              {t('sideMenuContent.backToTop')}
            </Button>
          </ButtonContainer>
        </HelpCenterContainer>
      </Container>
    </ThemeProvider>
  );
};

HelpCenter.propTypes = {
  properties: PropTypes.shape({
    analyticsScreenPath: PropTypes.string,
  }),
};

HelpCenter.defaultProps = {
  properties: {
    analyticsScreenPath: '/ReactSmbHelpCenter/',
  },
};

export default HelpCenter;
