import { useCallback, useEffect, useState } from 'react';
import { path } from 'ramda';
import {
  AccountMgtSvcClient,
  UserMgtSvcClientv3,
} from '@jarvis/web-stratus-client';
import {
  getOfferingOrEntitlement,
  useApiCall,
} from '@jarvis/react-portal-addons';

const ENTITLEMENTS = {
  INSTANT_INK: 'ws-hp.com/instantink',
  PRINT_PLANS: 'ws-hp.com/printplans',
  PRINT_ANYWHERE: 'ws-hp.com/potg',
  SUSTAINABILITY: 'ws-hp.com/positiveprinting',
  SMART_ADVANCE: 'ws-hp.com/smart-advance',
  SMART_PRO: 'ws-hp.com/smart-pro',
  SMART_SECURITY: 'ws-hp.com/smartsecurity',
  SMART_SECURITY_BASIC: 'ws-hp.com/smartsecurity-basic',
};

const STATES_ENTITLEMENTS = {
  disabled: 'DISABLED',
  enabled: 'ENABLED',
  pending: 'PENDING',
  published: 'Published',
};

export default function useProgramInfosApiCall({
  accountId,
  authProvider,
  stack,
  init,
}) {
  const [hasInitialFetched, setHasInitialFetched] = useState(false);
  const [hasInstantInk, setHasInstantInk] = useState(false);
  const [hasPrintPlans, setHasPrintPlans] = useState(false);
  const [hasPrintAnywhere, setHasPrintAnywhere] = useState(false);
  const [hasSmartAdvance, setHasSmartAdvance] = useState(false);
  const [hasSmartPro, setHasSmartPro] = useState(false);
  const [hasSmartSecurity, setHasSmartSecurity] = useState(false);
  const [hasSmartSecurityBasic, setHasSmartSecurityBasic] = useState(false);
  const [hasSustainability, setHasSustainability] = useState(false);
  const [isHpPlus, setIsHpPlus] = useState(false);
  const [isUcde, setIsUcde] = useState(false);

  const fetchUserInfo = useCallback(async () => {
    const userMgtSvcClient = new UserMgtSvcClientv3(stack, authProvider);
    const response = await userMgtSvcClient.getCurrentActiveUser();
    return response.data;
  }, [authProvider, stack]);

  const fetch = useCallback(async () => {
    const userInfo = await fetchUserInfo();
    const userId = userInfo.resourceId;

    const accountMgtSvcClient = new AccountMgtSvcClient(stack, authProvider);
    const programInfos = await accountMgtSvcClient.getProgramInfos(
      accountId,
      userId,
    );

    return programInfos;
  }, [accountId, authProvider, fetchUserInfo, stack]);

  const programInfosCall = useApiCall({
    authProvider,
    fetch,
    initialData: null,
    init,
  });

  const { error, isFetching } = programInfosCall;

  useEffect(() => {
    if (!hasInitialFetched && programInfosCall.data) {
      const fetchInfos = async () => {
        const hpPlus = path(['planInfo', 'programLevel'], programInfosCall.data.data)
          === 'HP+';
        const ucde = path(['planInfo', 'programLevel'], programInfosCall.data.data)
          === 'UCDE';

        const instantInk = getOfferingOrEntitlement(
          programInfosCall.data.data,
          ENTITLEMENTS.INSTANT_INK,
          {
            states: [
              STATES_ENTITLEMENTS.disabled,
              STATES_ENTITLEMENTS.enabled,
              STATES_ENTITLEMENTS.pending,
              STATES_ENTITLEMENTS.published,
            ],
          },
        );

        const printPlans = getOfferingOrEntitlement(
          programInfosCall.data.data,
          ENTITLEMENTS.PRINT_PLANS,
          {
            states: [
              STATES_ENTITLEMENTS.disabled,
              STATES_ENTITLEMENTS.enabled,
              STATES_ENTITLEMENTS.pending,
              STATES_ENTITLEMENTS.published,
            ],
          },
        );

        const printAnywhere = getOfferingOrEntitlement(
          programInfosCall.data.data,
          ENTITLEMENTS.PRINT_ANYWHERE,
          {
            states: [
              STATES_ENTITLEMENTS.enabled,
              STATES_ENTITLEMENTS.published,
            ],
          },
        );

        const smartPro = getOfferingOrEntitlement(
          programInfosCall.data.data,
          ENTITLEMENTS.SMART_PRO,
          {
            states: [STATES_ENTITLEMENTS.enabled],
          },
        );

        const sustainability = getOfferingOrEntitlement(
          programInfosCall.data.data,
          ENTITLEMENTS.SUSTAINABILITY,
          {
            states: [STATES_ENTITLEMENTS.enabled],
          },
        );

        const smartAdvance = getOfferingOrEntitlement(
          programInfosCall.data.data,
          ENTITLEMENTS.SMART_ADVANCE,
          {
            states: [
              STATES_ENTITLEMENTS.disabled,
              STATES_ENTITLEMENTS.enabled,
              STATES_ENTITLEMENTS.pending,
              STATES_ENTITLEMENTS.published,
            ],
          },
        );

        const smartSecurity = getOfferingOrEntitlement(
          programInfosCall.data.data,
          ENTITLEMENTS.SMART_SECURITY,
          {
            states: [
              STATES_ENTITLEMENTS.enabled,
              STATES_ENTITLEMENTS.published,
            ],
          },
        );

        const smartSecurityBasic = getOfferingOrEntitlement(
          programInfosCall.data.data,
          ENTITLEMENTS.SMART_SECURITY_BASIC,
          {
            states: [
              STATES_ENTITLEMENTS.enabled,
              STATES_ENTITLEMENTS.published,
            ],
          },
        );

        setIsHpPlus(hpPlus);
        setIsUcde(ucde);
        setHasInstantInk(instantInk);
        setHasPrintPlans(printPlans);
        setHasPrintAnywhere(printAnywhere);
        setHasSmartPro(smartPro);
        setHasSustainability(sustainability);
        setHasSmartAdvance(smartAdvance);
        setHasSmartSecurity(smartSecurity);
        setHasSmartSecurityBasic(smartSecurityBasic);
      };
      fetchInfos();
      setHasInitialFetched(true);
    }
  }, [hasInitialFetched, programInfosCall.data]);

  return {
    error,
    hasPrintPlans,
    hasInstantInk,
    hasPrintAnywhere,
    hasSmartAdvance,
    hasSmartPro,
    hasSmartSecurity,
    hasSmartSecurityBasic,
    hasSustainability,
    isFetching,
    isHpPlus,
    isUcde,
  };
}
