import PropTypes from 'prop-types';
import { path } from 'ramda';

const helpCenterActivity = 'HelpCenter-v01';
const helpCenterScreenPath = '/ReactSmbHelpCenter/';
const eventDetailOneDotThreeVersion = '1.3.0';

export const publishEvent = event => {
  const publishCdmEvents = path(['Shell', 'v1', 'analytics', 'publishCdmEvents'], window);

  if (!publishCdmEvents) {
    return;
  }

  publishCdmEvents([
    {
      dateTime: new Date().toISOString(),
      eventDetailType: 'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
      eventCategory: 'simpleUi',
      eventDetail: event,
      version: '1.4.0',
    },
  ]);
};

const ACTIONS = {
  MODULE_INITIALIZED: 'ModuleInitialized',
  MODULE_DISPLAYED: 'ModuleDisplayed',
  SCREEN_DISPLAYED: 'ScreenDisplayed',
  MODAL_WINDOW_DISPLAYED: 'ModalWindowDisplayed',
  CONTROL_ACCORDIAN_EXPANDED: 'ControlAccordianExpanded',
  CONTROL_ACCORDIAN_COLLAPSED: 'ControlAccordianCollapsed',
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked',
  CONTROL_HYPERLINK_CLICKED: 'ControlHyperLinkClicked',
};

export const AnalyticsEventPropType = {
  action: PropTypes.string.isRequired,
  activity: PropTypes.string.isRequired,
  screenPath: PropTypes.string.isRequired,
  screenName: PropTypes.string.isRequired,
  screenMode: PropTypes.string.isRequired,
  controlName: PropTypes.string.isRequired,
  controlDetail: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

// Events

const baseHelpCenterEventObject = {
  activity: helpCenterActivity,
  screenPath: `${helpCenterScreenPath}`,
  screenName: 'HelpCenter',
  version: eventDetailOneDotThreeVersion,
};

const baseConsumerHelpCenterEventObject = {
  ...baseHelpCenterEventObject,
  screenPath: '/ReactConsumerHelpCenter/',
};

export const helpCenterScreenDisplayed = (screenPath = helpCenterScreenPath) => ({
  ...baseHelpCenterEventObject,
  screenPath: `${screenPath}`,
  action: ACTIONS.SCREEN_DISPLAYED,
});

// HP Smart Admin Dashboard

export const HpSmartAdminDashboardAccordianExpanded = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_EXPANDED,
  activity: helpCenterActivity,
  controlName: 'HpSmartAdminDashboard',
};

export const HpSmartAdminDashboardAccordianCollapsed = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_COLLAPSED,
  controlName: 'HpSmartAdminDashboard',
};

export const HpSmartAdminDashboardWhatIsAdminDashboardButton = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'WhatIsAdminDashboardButton',
};

export const HpSmartAdminDashboardHomePageButton = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'HomePageButton',
};

// Managing Printers

export const ManagingPrintersAccordianExpanded = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_EXPANDED,
  controlName: 'ManagingPrinters',
};

export const ManagingPrintersAccordianCollapsed = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_COLLAPSED,
  controlName: 'ManagingPrinters',
};

export const ManagingPrintersPrinterPageButton = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'PrinterPageButton',
};

export const ManagingPrintersPrinterDetailsButton = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'PrinterDetailsButton',
};

export const ManagingPrintersChangePrinterNameLocButton = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'ChangePrinterNameLocButton',
};

export const ManagingPrintersTransferPrinterButton = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'TransferPrinterButton',
};

export const ManagingPrintersPrinterPageEwsLink = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlName: 'PrinterPageEwsLink',
};

// Managing Users

export const ManagingUsersAccordianExpanded = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_EXPANDED,
  controlName: 'ManagingUsers',
};

export const ManagingUsersAccordianCollapsed = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_COLLAPSED,
  controlName: 'ManagingUsers',
};

export const ManagingUsersUsersPageButton = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'UsersPageButton',
};

export const ManagingUsersAddingUsersButton = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'AddingUsersButton',
};

export const ManagingUsersRemovingUsersButton = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'RemovingUsersButton',
};

export const ManagingUsersChangingUsersRoleButton = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'ChangingUsersRoleButton',
};

// HP Instant Ink

export const HpInstantInkAccordianExpanded = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_EXPANDED,
  controlName: 'HpInstantInk',
};

export const HpInstantInkAccordianCollapsed = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_COLLAPSED,
  controlName: 'HpInstantInk',
};

export const HpInstantInkWhatIsHpInkButton = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'WhatIsHpInstantInkButton',
};

export const HpInstantInkInfoLink = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlName: 'HpInstantInkInfoLink',
};

// Solutions

export const SolutionsAccordianExpanded = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_EXPANDED,
  controlName: 'Solutions',
};

export const SolutionsAccordianCollapsed = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_COLLAPSED,
  controlName: 'Solutions',
};

export const SolutionsWhatAreHpSolutionsButton = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'WhatAreHpSolutionsButton',
};

export const SolutionsHpPlusButton = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'HpPlusButton',
};

export const SolutionHpSmartProButton = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'HpSmartProButton',
};

export const SolutionsHpPlusInfoLink = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlName: 'HpPlusInfoLink',
};

// Sustainability

export const SustainabilityAccordianExpanded = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_EXPANDED,
  controlName: 'Sustainibility',
};

export const SustainabilityAccordianCollapsed = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_COLLAPSED,
  controlName: 'Sustainibility',
};

export const SustainabilityGoGreenButton = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'GoGreenButton',
};

// Printing

export const PrintingAccordianExpanded = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_EXPANDED,
  controlName: 'Printing',
};

export const PrintingAccordianCollapsed = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_COLLAPSED,
  controlName: 'Printing',
};

export const PrintingUsingHpSmartPrintingButton = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'UsingHpSmartPrintingButton',
};

export const PrintingHpSmartPrintingLink = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlName: 'HpSmartPrintingLink',
};

export const PrintingRemotePrintingButton = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'RemotePrintingButton',
};

// Scan and Fax Settings

export const ScanFaxSettingsAccordianExpanded = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_EXPANDED,
  controlName: 'ScanFaxSettings',
};

export const ScanFaxSettingsAccordianCollapsed = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_COLLAPSED,
  controlName: 'ScanFaxSettings',
};

export const ScanFaxSettingsSetupScanButton = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'SetupScanButton',
};

export const ScanFaxSettingsSetupFaxButton = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'SetupFaxButton',
};

// Account

export const AccountAccordianExpanded = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_EXPANDED,
  controlName: 'Account',
};

export const AccountAccordianCollapsed = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_COLLAPSED,
  controlName: 'Account',
};

export const AccountUsersManageAccount = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'ManageAccountButton',
};

// Additional Help and Support

export const AdditionalHelpAccordianExpanded = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_EXPANDED,
  controlName: 'AdditionalHelp',
};

export const AdditionalHelpAccordianCollapsed = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_COLLAPSED,
  controlName: 'AdditionalHelp',
};

export const AdditionalHelpUsingHpSmartAdditionalHelpButton = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'PrinterSupportButton',
};

export const AdditionalHelpHpSmartAdditionalHelpLink = {
  ...baseHelpCenterEventObject,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlName: 'HpSupportLink',
};

// About HP Smart

export const AboutHpSmartAccordianExpanded = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_EXPANDED,
  controlName: 'AboutHpSmart',
};

export const AboutHpSmartAccordianCollapsed = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_COLLAPSED,
  controlName: 'AboutHpSmart',
};

export const AboutHpSmartGettingToKnowButton = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'KnowHpSmart',
  controlDetail: 'AboutHpSmart',
};

export const AboutHpSmartStartingOffButton = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'StartingOff',
  controlDetail: 'AboutHpSmart',
};

export const AboutHpSmartSharingFilesButton = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'SharingFiles',
  controlDetail: 'AboutHpSmart',
};

// HP Plus

export const HpPlusAccordianExpanded = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_EXPANDED,
  controlName: 'HpPlus',
};

export const HpPlusAccordianCollapsed = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_COLLAPSED,
  controlName: 'HpPlus',
};

export const HpPlusWhatIsHpPlusButton = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'WhatIsHpPlus',
  controlDetail: 'HpPlus',
};

export const HpPlusHpSmartSecurityButton = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'HpSmartSecurity',
  controlDetail: 'HpPlus',
};

// HP Smart Advance

export const HpSmartAdvanceAccordianExpanded = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_EXPANDED,
  controlName: 'HpSmartAdvance',
};

export const HpSmartAdvanceAccordianCollapsed = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_COLLAPSED,
  controlName: 'HpSmartAdvance',
};

export const HpSmartAdvanceWhatIsHpSmartAdvanceButton = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'WhatIsHpSmartAdvance',
  controlDetail: 'HpSmartAdvance',
};

export const HpSmartAdvancePrintAnywhereButton = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'PrintAnywhere',
  controlDetail: 'HpSmartAdvance',
};

// HP Instant Ink

export const HpInstantInkConsumerAccordianExpanded = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_EXPANDED,
  controlName: 'HpInstantInk',
};

export const HpInstantInkConsumerAccordianCollapsed = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_COLLAPSED,
  controlName: 'HpInstantInk',
};

export const HpInstantInkConsumerLearnMoreButton = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'LearnMore',
  controlDetail: 'HpInstantInk',
};

// Printer Connection Information

export const PrinterConnectionInformationAccordianExpanded = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_EXPANDED,
  controlName: 'PrinterConnectionInformation',
};

export const PrinterConnectionInformationAccordianCollapsed = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_COLLAPSED,
  controlName: 'PrinterConnectionInformation',
};

export const PrinterConnectionInformationPrinterSupportButton = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'PrinterSupport',
  controlDetail: 'PrinterConnectionInformation',
};

export const PrinterConnectionInformationFindYourPrinterButton = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'FindYourPrinter',
  controlDetail: 'PrinterConnectionInformation',
};

export const PrinterConnectionInformationConnectionYourPrinterButton = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'ConnectionYourPrinter',
  controlDetail: 'PrinterConnectionInformation',
};

export const PrinterConnectionInformationViewingPrinterInformationButton = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'ViewingPrinterInformation',
  controlDetail: 'PrinterConnectionInformation',
};

export const PrinterConnectionInformationPrintServicePluginButton = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'PrintServicePlugin',
  controlDetail: 'PrinterConnectionInformation',
};

// Print, Scan and Share

export const PrintScanShareAccordianExpanded = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_EXPANDED,
  controlName: 'PrintScanShare',
};

export const PrintScanShareAccordianCollapsed = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_COLLAPSED,
  controlName: 'PrintScanShare',
};

export const PrinterScanSharePrintingButton = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'Printing',
  controlDetail: 'PrintScanShare',
};

export const PrinterScanShareScanningButton = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'Scanning',
  controlDetail: 'PrintScanShare',
};

export const PrinterScanShareShortcutsButton = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'Shortcuts',
  controlDetail: 'PrintScanShare',
};

export const PrinterScanShareFaxButton = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'Fax',
  controlDetail: 'PrintScanShare',
};

// Additional Help and Support

export const AdditionalHelpAndSupportAccordianExpanded = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_EXPANDED,
  controlName: 'AdditionalHelpAndSupport',
};

export const AdditionalHelpAndSupportAccordianCollapsed = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_ACCORDIAN_COLLAPSED,
  controlName: 'AdditionalHelpAndSupport',
};

export const AdditionalHelpAndSupportPrintAnywhereOnlineSupportButton = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'PrintAnywhereOnlineSupport',
  controlDetail: 'AdditionalHelpAndSupport',
};

export const AdditionalHelpAndSupportShortcutsOnlineSupportButton = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'ShortcutsOnlineSupport',
  controlDetail: 'AdditionalHelpAndSupport',
};

export const AdditionalHelpAndSupportHpMobilePrintingButton = {
  ...baseConsumerHelpCenterEventObject,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'HpMobilePrinting',
  controlDetail: 'AdditionalHelpAndSupport',
};

export const helpAppHelpSectionAnalyticsIds = {
  'what-is-smart-admin-dashboard': 'What is Smart Admin Dashboard?',
  'home-page': 'Home Page',
  'printers-page': 'Printers Page',
  'printer-details-page': 'Printer Details Page',
  'changing-printer-name-or-printer-location': 'Changing Printer Name or Printer Location',
  'transferring-printer-froma-personal-hp-account': 'Transferring Printer from a Personal HP Account',
  'users-page': 'Users Page',
  'adding-users': 'Adding Users',
  'removing-users': 'Removing Users',
  'changing-user-roles': 'Changing User Roles',
  'what-is-hp-instant-ink': 'What Is HP Instant Ink?',
  'what-are-hp-solutions': 'What Are HP Solutions?',
  'hp+': 'HP+',
  'hp-solutions': 'HP Solutions',
  'hp-smart-pro': 'HP Smart Pro',
  'go-green-with-hp': 'Go Green with HP',
  'using-hp-smart-printing': 'Using HP Smart Printing',
  'remote-printing': 'Remote Printing',
  'setting-up-scan-to-email-and-scan-destinations': 'Setting Up Scan to Email and Scan Destinations',
  'setting-up-fax': 'Setting Up Fax',
  'managing-your-account': 'Managing Your Account',
  'printer-support': 'Printer Support',
  'getting-to-know-hp-smart': 'Getting to Know HP Smart',
  'starting-off': 'Starting Off',
  'sharing-files': 'Sharing Files',
  'what-is-hp-plus': 'whatIsHPPlus',
  'hp-smart-security': 'hpSmartSecurity',
  'what-is-hp-smart-advance': 'HPSmartAdvNoHP+',
  'print-anywhere': 'PrintAnywhereAdvance',
  'learn-more-instant-ink': 'Learn More',
  'finding-your-printer': 'Finding Your Printer',
  'connecting-to-your-printer': 'Connecting to Your Printer',
  'viewing-printer-information': 'Viewing Printer Information',
  'print-service-plugin': 'Print Service Plugin',
  printing: 'Printing',
  scanning: 'Scanning',
  shortcuts: 'Shortcuts',
  fax: 'Fax',
  'print-anywhere-online-support': 'Print Anywhere Online Support',
  'shortcuts-online-support': 'Shortcuts Online Support',
  'hp-mobile-printing': 'HP Mobile Printing',
};

// Object Helpers

export const hpSmartAdminDashboard = {
  collapsed: HpSmartAdminDashboardAccordianCollapsed,
  expanded: HpSmartAdminDashboardAccordianExpanded,
  [helpAppHelpSectionAnalyticsIds['what-is-smart-admin-dashboard']]: HpSmartAdminDashboardWhatIsAdminDashboardButton,
  [helpAppHelpSectionAnalyticsIds['home-page']]: HpSmartAdminDashboardHomePageButton,
};

export const managingPrinters = {
  collapsed: ManagingPrintersAccordianCollapsed,
  expanded: ManagingPrintersAccordianExpanded,
  [helpAppHelpSectionAnalyticsIds['printers-page']]: ManagingPrintersPrinterPageButton,
  [helpAppHelpSectionAnalyticsIds['printer-details-page']]: ManagingPrintersPrinterDetailsButton,
  [helpAppHelpSectionAnalyticsIds['changing-printer-name-or-printer-location']]:
    ManagingPrintersChangePrinterNameLocButton,
  [helpAppHelpSectionAnalyticsIds['transferring-printer-froma-personal-hp-account']]:
    ManagingPrintersTransferPrinterButton,
  link: ManagingPrintersPrinterPageEwsLink,
};

export const managingUsers = {
  collapsed: ManagingUsersAccordianCollapsed,
  expanded: ManagingUsersAccordianExpanded,
  [helpAppHelpSectionAnalyticsIds['users-page']]: ManagingUsersUsersPageButton,
  [helpAppHelpSectionAnalyticsIds['adding-users']]: ManagingUsersAddingUsersButton,
  [helpAppHelpSectionAnalyticsIds['removing-users']]: ManagingUsersRemovingUsersButton,
  [helpAppHelpSectionAnalyticsIds['changing-user-roles']]: ManagingUsersChangingUsersRoleButton,
};

export const hpInstantInk = {
  collapsed: HpInstantInkAccordianCollapsed,
  expanded: HpInstantInkAccordianExpanded,
  [helpAppHelpSectionAnalyticsIds['what-is-hp-instant-ink']]: HpInstantInkWhatIsHpInkButton,
  link: HpInstantInkInfoLink,
};

export const solutions = {
  collapsed: SolutionsAccordianCollapsed,
  expanded: SolutionsAccordianExpanded,
  [helpAppHelpSectionAnalyticsIds['what-are-hp-solutions']]: SolutionsWhatAreHpSolutionsButton,
  [helpAppHelpSectionAnalyticsIds['hp+']]: SolutionsHpPlusButton,
  [helpAppHelpSectionAnalyticsIds['hp-smart-pro']]: SolutionHpSmartProButton,
  link: SolutionsHpPlusInfoLink,
};

export const sustainability = {
  collapsed: SustainabilityAccordianCollapsed,
  expanded: SustainabilityAccordianExpanded,
  [helpAppHelpSectionAnalyticsIds['go-green-with-hp']]: SustainabilityGoGreenButton,
};

export const printing = {
  collapsed: PrintingAccordianCollapsed,
  expanded: PrintingAccordianExpanded,
  [helpAppHelpSectionAnalyticsIds['using-hp-smart-printing']]: PrintingUsingHpSmartPrintingButton,
  link: PrintingHpSmartPrintingLink,
  [helpAppHelpSectionAnalyticsIds['remote-printing']]: PrintingRemotePrintingButton,
};

export const scanFax = {
  collapsed: ScanFaxSettingsAccordianCollapsed,
  expanded: ScanFaxSettingsAccordianExpanded,
  [helpAppHelpSectionAnalyticsIds['setting-up-scan-to-email-and-scan-destinations']]: ScanFaxSettingsSetupScanButton,
  [helpAppHelpSectionAnalyticsIds['setting-up-fax']]: ScanFaxSettingsSetupFaxButton,
};

export const account = {
  collapsed: AccountAccordianCollapsed,
  expanded: AccountAccordianExpanded,
  [helpAppHelpSectionAnalyticsIds['managing-your-account']]: AccountUsersManageAccount,
};

export const additionalHelp = {
  collapsed: AdditionalHelpAccordianCollapsed,
  expanded: AdditionalHelpAccordianExpanded,
  [helpAppHelpSectionAnalyticsIds['printer-support']]: AdditionalHelpUsingHpSmartAdditionalHelpButton,
  link: AdditionalHelpHpSmartAdditionalHelpLink,
};

export const aboutHpSmart = {
  collapsed: AboutHpSmartAccordianCollapsed,
  expanded: AboutHpSmartAccordianExpanded,
  [helpAppHelpSectionAnalyticsIds['getting-to-know-hp-smart']]: AboutHpSmartGettingToKnowButton,
  [helpAppHelpSectionAnalyticsIds['starting-off']]: AboutHpSmartStartingOffButton,
  [helpAppHelpSectionAnalyticsIds['sharing-files']]: AboutHpSmartSharingFilesButton,
};

export const HpPlus = {
  collapsed: HpPlusAccordianCollapsed,
  expanded: HpPlusAccordianExpanded,
  [helpAppHelpSectionAnalyticsIds['what-is-hp-plus']]: HpPlusWhatIsHpPlusButton,
  [helpAppHelpSectionAnalyticsIds['hp-smart-security']]: HpPlusHpSmartSecurityButton,
};

export const hpSmartAdvance = {
  collapsed: HpSmartAdvanceAccordianCollapsed,
  expanded: HpSmartAdvanceAccordianExpanded,
  [helpAppHelpSectionAnalyticsIds['what-is-hp-smart-advance']]: HpSmartAdvanceWhatIsHpSmartAdvanceButton,
  [helpAppHelpSectionAnalyticsIds['print-anywhere']]: HpSmartAdvancePrintAnywhereButton,
};

export const hpInstantInkConsumer = {
  collapsed: HpInstantInkConsumerAccordianCollapsed,
  expanded: HpInstantInkConsumerAccordianExpanded,
  [helpAppHelpSectionAnalyticsIds['learn-more-instant-ink']]: HpInstantInkConsumerLearnMoreButton,
};

export const printerConnectionInformation = {
  collapsed: PrinterConnectionInformationAccordianCollapsed,
  expanded: PrinterConnectionInformationAccordianExpanded,
  [helpAppHelpSectionAnalyticsIds['printer-support']]: PrinterConnectionInformationPrinterSupportButton,
  [helpAppHelpSectionAnalyticsIds['finding-your-printer']]: PrinterConnectionInformationFindYourPrinterButton,
  [helpAppHelpSectionAnalyticsIds['connecting-to-your-printer']]: (
    PrinterConnectionInformationConnectionYourPrinterButton
  ),
  [helpAppHelpSectionAnalyticsIds['viewing-printer-information']]: (
    PrinterConnectionInformationViewingPrinterInformationButton
  ),
  [helpAppHelpSectionAnalyticsIds['print-service-plugin']]: PrinterConnectionInformationPrintServicePluginButton,
};

export const printScanShare = {
  collapsed: PrintScanShareAccordianCollapsed,
  expanded: PrintScanShareAccordianExpanded,
  [helpAppHelpSectionAnalyticsIds.printing]: PrinterScanSharePrintingButton,
  [helpAppHelpSectionAnalyticsIds.scanning]: PrinterScanShareScanningButton,
  [helpAppHelpSectionAnalyticsIds.shortcuts]: PrinterScanShareShortcutsButton,
  [helpAppHelpSectionAnalyticsIds.fax]: PrinterScanShareFaxButton,
};

export const additionalHelpAndSupport = {
  collapsed: AdditionalHelpAndSupportAccordianCollapsed,
  expanded: AdditionalHelpAndSupportAccordianExpanded,
  [helpAppHelpSectionAnalyticsIds['print-anywhere-online-support']]: (
    AdditionalHelpAndSupportPrintAnywhereOnlineSupportButton
  ),
  [helpAppHelpSectionAnalyticsIds['shortcuts-online-support']]: AdditionalHelpAndSupportShortcutsOnlineSupportButton,
  [helpAppHelpSectionAnalyticsIds['hp-mobile-printing']]: AdditionalHelpAndSupportHpMobilePrintingButton,
};

export const SmbAndConsumerDuplicatedSlugs = [
  'hp-instant-ink',
  'additional-help-and-support',
];

export const helpCenterEvents = {
  'hp-smart-admin-dashboard': hpSmartAdminDashboard,
  'managing-printers-in-hp-smart-admin': managingPrinters,
  'managing-users-in-hp-smart-admin': managingUsers,
  'hp-instant-ink': hpInstantInk,
  solutions,
  sustainability,
  printing,
  'scan-and-fax-settings': scanFax,
  account,
  'additional-help-and-support': additionalHelp,
  'about-hp-smart': aboutHpSmart,
  'hp-plus': HpPlus,
  'hp-smart-advance': hpSmartAdvance,
  'hp-instant-ink-consumer': hpInstantInkConsumer,
  'printer-and-connection-information': printerConnectionInformation,
  'print-scan-and-share': printScanShare,
  'additional-help-and-support-consumer': additionalHelpAndSupport,
  // TODO: remove when migration to content stack reaches to production
  2928195: hpSmartAdminDashboard,
  2928923: managingPrinters,
  2930105: managingUsers,
  2930658: hpInstantInk,
  2931361: solutions,
  2931614: sustainability,
  2932367: printing,
  2932824: scanFax,
  2933052: account,
  2933205: additionalHelp,
};
