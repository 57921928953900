import { mixins } from '@jarvis/react-portal-addons';
import styled from 'styled-components';
import { Button as VeneerButton } from '@veneer/core';
import primitives from '@veneer/primitives';

export const Container = styled.div`
  column-gap: ${({ theme }) => theme.orbitalSix};
  display: grid;
  grid-template-rows: min-content min-content min-content auto;
  grid-template-columns: repeat(12, 1fr);
  padding: ${primitives.layout.size0}px
  ${({ theme }) => theme.orbitalSix};
  > * {
    grid-column: 1 / -1;
  }
`;

export const Description = styled.div`
  margin-bottom: ${({ theme }) => theme.orbitalFour};
`;

export const NavigationContainer = styled.div``;

export const HelpCenterContainer = styled.div`
  gap: ${({ theme }) => theme.orbitalSix};
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: min-content;

  ${mixins.lg(`
    grid-template-columns: repeat(12, 1fr);
  `)}
`;

export const Title = styled.div`
  margin-bottom: ${({ theme }) => theme.orbitalFour};
`;

export const ButtonContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: 3;

  ${mixins.lg(`
    display: none;
  `)}
`;

export const Button = styled(VeneerButton)`
  color: ${({ theme }) => theme.color.primary.default} !important;
`;

export default Container;
